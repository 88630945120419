var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": _vm.title,
      "actions": _vm.actions
    }
  }, [_vm._v(" Вы действительно хотите удалить " + _vm._s(_vm.plural(_vm.items.length)) + " из этого списка мониторинга? ")]);
};
var staticRenderFns = [];
export { render, staticRenderFns };