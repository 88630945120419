import "core-js/modules/esnext.iterator.map.js";
import { DataTable, ColWidth } from "@/components/DataTable/DataTable";
////import { getEntityTableColumns } from "@/utils/enums";
import { dateRangeDaysArray, dateFormat } from "@/utils/dates";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    },
    list: {
      type: Object,
      required: true
    },
    NA: {
      type: String,
      default: "н/д"
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      return this.$store.dispatch("listM/getListMSellers", {
        ...query,
        ...this.reportSettings
      });
    },
    href_s_id_BZ(item) {
      //console.log('90: this.list=',this.list)
      let href = "/" + (this.list.mp == "wb" ? "ozon" : "wb") + "/seller/" + item.s_id_BZ + "/info";
      return href;
      //return item.s_id_BZ;
    }
  },
  computed: {
    fileName() {
      return `${this.routeParams.mp.toUpperCase()} Cписок ${this.list.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    columns() {
      const days = dateRangeDaysArray(this.reportSettings.date, this.reportSettings.date2);
      let position = 2;
      return [{
        title: "Продавец",
        position: 1,
        show: true,
        name: "seller",
        width: ColWidth('seller'),
        filter: "text",
        type: "link",
        getLink: item => {
          return {
            route: {
              name: "SellerInfo",
              params: {
                ...this.routeParams,
                s_id: item.s_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.seller,
            target: "_blank"
          };
        }
      }, {
        //&: 2024-11-29: INN/OGRN
        title: "ИНН продавца",
        name: "s_inn",
        show: true,
        type: "slot",
        width: 140,
        filter: "numberRange"
      }, {
        title: "ОГРН продавца",
        name: "s_ogrn",
        show: true,
        type: "slot",
        width: 140,
        filter: "numberRange"
      }, {
        title: "Продает на OZON и WB",
        name: "s_id_BZ",
        show: true,
        type: "slot",
        width: 120,
        filter: "numberRange"
      },
      ////...getEntityTableColumns("seller", 2),

      /*
              // &: 2022-05-21
              // {
              //     title: "Впервые",
              //     show: true,
              //     name: "date",
              //     width: 100,
              //     filter: "dateRange",
              //     type: "date",
              // },
              // {
              //     title: "Последний раз",
              //     show: true,
              //     name: "date2",
              //     width: 110,
              //     filter: "dateRange",
              //     type: "date",
              // },
              */

      ...days.map(date => {
        return {
          title: dateFormat(date),
          position: ++position,
          show: true,
          name: date,
          width: 100,
          type: "number",
          filter: "numberRange",
          noSettings: true
        };
      })];
    }
  }
};