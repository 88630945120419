import DataTable from "@/components/DataTable/DataTable";
////import { getEntityTableColumns } from "@/utils/enums";
////import { dateRangeDaysArray, dateFormat } from "@/utils/dates";

export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    },
    list: {
      type: Object,
      required: true
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      return this.$store.dispatch("listM/getListMProductsNewNameDetail", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    fileName() {
      return `${this.routeParams.mp.toUpperCase()} Детализация cписка ${this.list.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    columns() {
      return [{
        title: "Товар",
        position: 1,
        show: true,
        name: "title",
        width: 380,
        filter: "text",
        type: "link",
        noOverflow: true,
        getImage: item => item.image,
        getLink: item => {
          return {
            route: {
              name: "ProductInfo",
              params: {
                ...this.routeParams,
                p_id: item.p_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.title,
            target: "_blank"
          };
        }
      }, {
        title: "SKU",
        position: 2,
        show: true,
        name: "sku",
        width: 100,
        filter: "text",
        type: "link",
        getLink: item => {
          return {
            href: item.mp_url,
            text: item.sku
          };
        }
      }, {
        title: "Дата",
        position: 3,
        show: true,
        name: "date_new",
        width: 100,
        filter: "dateRange",
        type: "date"
      }, {
        title: "Было",
        position: 4,
        show: true,
        name: "title_old",
        width: 260,
        filter: "text",
        type: "text"
      }, {
        title: "Стало",
        position: 5,
        show: true,
        name: "title_new",
        width: 260,
        filter: "text",
        type: "text"
      }];
    }
  }
};